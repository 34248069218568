import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"
import HeroImage from "../components/heroImage"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import BackContact from "../components/backContact"

const Blog = ({ data, pageContext, location }) => {
  const title = `お知らせ・ブログ${pageContext.currentPage} | 大阪の司法書士事務所`
  const description = `大阪の司法書士事務所、司法書士法人みつわ合同事務所の新着記事の${pageContext.currentPage}ページ目です。今後も、相続関連、登記関連のお役立ち情報を発信していきますので、ご覧ください。またSNSアカウントも併せてご覧ください。`
  return (
    <Layout>
      <SEO
        description={description}
        title={title}
        pagepath={location.pathname}
        pageimg={data.blogList.childImageSharp.original.src}
        pageimgw={data.blogList.childImageSharp.original.width}
        pageimgh={data.blogList.childImageSharp.original.height}
      />
      <HeroImage
        name={"bloglist"}
        fluid={data.blogList.childImageSharp.fluid}
        title={"司法書士法人みつわ合同事務所"}
        subTitle={"お知らせ・ブログ一覧"}
      />
      <section className="content bloglist">
        <div className="bloglist__container">
          <h1 className="bloglist__title">新着記事</h1>
          <div className="bloglist__posts">
            {data.allContentfulBlogPost.edges.map(({ node }) => (
              <Link to={`/blog/news/${node.slug}/`} key={node.id}>
                <article className="bloglist__post">
                  <FontAwesomeIcon icon={faNewspaper} />
                  <h2 className="bloglist__post__title">{node.title}</h2>
                  <span className="bloglist__post__date">
                    投稿日：{node.createdDate}
                  </span>
                </article>
              </Link>
            ))}
          </div>
          <ul className="pagenation">
            {!pageContext.isFirst && (
              <li className="prev">
                <Link
                  to={
                    pageContext.currentPage === 2
                      ? `/blog/`
                      : `/blog/${pageContext.currentPage - 1}/`
                  }
                  rel="prev"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>前のページ</span>
                </Link>
              </li>
            )}
            {!pageContext.isLast && (
              <li className="next">
                <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
                  <span>次のページ</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </section>
      <BackContact link={"/"} linkName={"Topページ"} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: createdDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          createdDate(formatString: "YYYY年MM月DD日 HH:mm")
          slug
        }
      }
    }
    blogList: file(relativePath: { eq: "news.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
        original {
          height
          src
          width
        }
      }
    }
  }
`
